import React from "react";
import "./Footer.scss";
import instaLogo from "../images/instagram.png";
import youtubeLogo from "../images/youtube.png";
import linkedinLogo from "../images/linkedin-icon.png";
import email from "../images/email.png";
import telefone from "../images/telefone.png";
import endereco from "../images/endereco.png";
import btn_voltar from "../images/botao-voltar.png";
import whatsappLogo from "../images/whatsapp.png";
import logo_rodape from '../images/logo-rodape.png'
import rodape from '../images/rodape.jpg'
import { Link } from 'react-router-dom'


function Footer() {
  function scrollToTop() {
    window.scrollTo(0, 0)
  }

  const year = new Date().getFullYear();
  return (
    <div id="footer">
      <a className="whatsapp" href="https://api.whatsapp.com/send?phone=5511932878332&text=Olá. Entrei no site OA Comunicação Digital e gostaria de receber mais informações para orçamento!" target="blank" title="Botão para solicitar orçamento">
        <img src={whatsappLogo} alt="wehatssApp" />
      </a>
      <div className="wrap_footer">
        <div className="w_logos" style={{ backgroundImage: `url(${rodape})` }}>
          <div className="shadow"></div>
          <div className="logo_oa" onClick={scrollToTop} title="link para home" >
            <Link to="/" title="link para home">
              <img src={logo_rodape} alt='Logo OA Comunicação' />
            </Link>
          </div>
        </div>
        <div className="wrapper">
          <a className="btn-orcamento" href="https://api.whatsapp.com/send?phone=5511932878332&text=Olá. Entrei no site OA Comunicação Digital e gostaria de receber mais informações para orçamento!" target="blank" title="Botão para solicitar orçamento">
            <p>Fale conosco!</p>
          </a>
          <div className="info redes">
          <p>Siga-nos: &nbsp;</p>
            <div className="links">
              <a
                href="https://www.instagram.com/oacomunicacaodigital/"
                target="blank"
              >
                <img src={instaLogo} alt="ícone instagram" />
              </a>
            </div>
            <div className="links">
              <a
                href="https://www.youtube.com/@oacomunicacaodigital"
                target="blank"
              >
                <img src={youtubeLogo} alt="ícone instagram" />
              </a>
            </div>
            <div className="links">
              <a
                href="https://www.linkedin.com/company/oa-comunica%C3%A7%C3%A3o-digital/"
                target="blank"
              >
                <img src={linkedinLogo} alt="ícone Linkedin" />
              </a>
            </div>
          </div>
          <div className="info mail">
            <a href="mailto:adm@oacomunicacaodigital.com.br">
              <img src={email} alt="ícone email" />
              <span className="email_text">adm@oacomunicacaodigital.com.br</span>
            </a>
          </div>
          <div className="info tel">
            <a href="tel:+5511932878332">
              <img src={telefone} alt="ícone telefone" />
              <p>+55 11 93287-8332</p></a>
          </div>
          <div className="info">
            <img src={endereco} alt="ícone endereço" className="iadress" />
            <div>
              <p>Avenida Paulista, 1636</p>
              <p>Conj 4 Pavmto15 Sala 1504</p>
              <p> Bela Vista, São Paulo - Brasil</p>
            </div>
          </div>
          <div className="info">
            <img src={endereco} alt="ícone endereço" className="iadress" />
            <div>
              <p>Rua Rio Grande, 821</p>
              <p>Vila Mariana, São Paulo - Brasil</p>
            </div>
          </div>
          <div className="info" onClick={scrollToTop}>
            <img src={btn_voltar} alt="ícone endereço" className="btn_voltar" />
          </div>
        </div>
      </div>

      <div className="copy">
        <p>CNPJ: 57.468.484/0001-15 <span className="desktop">&nbsp;&nbsp;&nbsp;</span> <br className="mobile" />© OA Comunicação {year}. Todos os direitos reservados.</p>
      </div>
    </div>
  );
}

export default Footer;
